"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { DcBaseComponent } from "@deleteagency/dc";
const ReactModal = lazy(() => import("components/react-modal/js/react-modal"));
export default class OurStoryComponent extends DcBaseComponent {
  constructor() {
    super(...arguments);
    __publicField(this, "unmountModal", () => {
      var _a;
      (_a = this.modalRoot) == null ? void 0 : _a.unmount();
    });
  }
  static getNamespace() {
    return "our-story";
  }
  onInit() {
    this.modalContainer = document.createElement("div");
    document.body.appendChild(this.modalContainer);
    this._addListeners();
  }
  _addListeners() {
    this.addListener(this.element, "click", this.openModal.bind(this));
  }
  openModal(event) {
    var _a;
    event.preventDefault();
    if (!((_a = this.options) == null ? void 0 : _a.videoId))
      return;
    this.modalRoot = createRoot(this.modalContainer);
    this.modalRoot.render(
      /* @__PURE__ */ React.createElement(Suspense, { fallback: /* @__PURE__ */ React.createElement("div", { className: "spinner" }) }, /* @__PURE__ */ React.createElement(ReactModal, { isOpen: true, onAfterClose: this.unmountModal }, /* @__PURE__ */ React.createElement(Suspense, { fallback: /* @__PURE__ */ React.createElement("div", { className: "spinner" }) }, /* @__PURE__ */ React.createElement("div", { className: "design-video" }, /* @__PURE__ */ React.createElement(
        "iframe",
        {
          src: `https://player.vimeo.com/video/${this.options.videoId}?autoplay=0&controls=1&muted=0`,
          title: this.options.title,
          frameBorder: "0",
          webkitallowfullscreen: true,
          mozallowfullscreen: true,
          allowFullScreen: true
        }
      )))))
    );
  }
  onDestroy() {
    this.element.removeEventListener("click", this.openModal);
  }
}
